<template>
    <v-content class="follow-map">
        <div id="maphead" class="text-center subtitle-2 font-weight-bold">
            【近くのお店を表示中】お店をフォローしよう！
        </div>
        <div id="map">
            <GmapMap :center="center" ref="mapRef" :zoom="zoom" :options="mapStyle" style="width: 100%; height: 100%;">
                <GmapMarker v-for="(m,id) in changeShopList" :position="m.location" :title="m.name" :clickable="true" :draggable="false" :key="id" @click="MakerClick(m.location, id)"></GmapMarker>
            </GmapMap>
        </div>

        <div class="slider">
            <slick ref="slick" :options="slickOptions" @afterChange="handleAfterChange">
              <div v-for="(shop, index) in changeShopList" :key="index">
                <div style="margin: 0 10px;">
                <v-card height="164px">
                    <v-img v-if="shop.image_path" :src="shop.image_path" lazy-src="@/assets/noimg@2x.png" eager height="100px"></v-img>
                    <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" height="100px"></v-img>

                    <div class="aligin_center">
                    <v-card-title class="text-left body-1 py-2" style="line-height: 1.4rem;">
                        {{shop.name | truncate(22)}}
                    </v-card-title>
                    </div>

                      <transition enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
                    <div class="followbtn" v-show="shop.follow_status == 1" @click="followSet(0, index, shop.id)">
                        <img src="@/assets/follow-map/followbtn_on.svg">
                    </div>
                                          </transition>

                      <transition enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
                    <div class="followbtn" v-show="shop.follow_status == 0" @click="followSet(1, index, shop.id)">
                        <img src="@/assets/follow-map/followbtn.svg">
                    </div>

                      </transition>
                </v-card>
                </div>
              </div>
            </slick>
        </div>

        <v-row class="btns" no-gutters>
            <v-col cols="6">
                <div class="text-center">
                  <!-- 20201221 NEW_DEV-734 A12-No.19 cyber 段 start -->
                    <v-menu offset-y max-height="calc(100% - 16px)">
                      <!-- 20201221 NEW_DEV-734 A12-No.19 cyber 段 end -->
                        <template v-slot:activator="{ on }">
                            <v-btn class="maincolor-bg" rounded dark v-on="on">
                                <v-icon size="small">fas fa-search</v-icon> 都道府県指定
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item>
                               <v-list-item-title @click="changeRefecture('')">すべて</v-list-item-title>
                               </v-list-item>
                            <v-list-item v-for="(item, index) in result.prefecture_list" :key="index">
                                <v-list-item-title @click="changeRefecture(item.id)">{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            <v-col cols="6" class="text-center">
                <v-btn rounded class="maincolor-btn2 default_button mb-4" @click="NextRouting()">次へ進む</v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent content-class="no_overflow">
                <v-card class="text-center pa-5">
                    <div class="star text-center"><img src="@/assets/follow-map/star.svg"></div>
                    <h3 class="subtitle-1 font-weight-bold mb-7" style="color: #FFB100;">お店をフォローしよう！</h3>
                    <p class="fontsize13 mb-7 text-center">よく訪れる店舗やご自宅・職場から近い店舗を<br>登録してお得な情報をゲット!!</p>
                    <div class="text-center">

                        <v-btn rounded class="maincolor-bg default_button animate__animated" @click="dialog = false">OK</v-btn>

                    </div>
                </v-card>
        </v-dialog>

    <v-overlay :value="loading" z-index=9999>
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

    </v-content>
</template>

<script>
import Slick from 'vue-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
export default {
  name: "GoogleMap",
  components: {
    Slick
  },
  data: () => ({
    center: [],
    zoom: 14,
    mapTypeControl: false,
    marker_items: [],
    mapStyle: {
        disableDefaultUI: true,
    },
    slickOptions: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '60px',
        arrows: false,
        responsive: true,
    },
    result: [],
    dialog: true,
    refecture_id: '',
    routing: [],
    loading: false,
  }),
  async created() {
    this.init = this.storageGet('*')

    var shoplist_req = { params:{
      'sid': this.init.sid,
      'service_cd': this.init.service_cd,
      'device_uid': this.init.device_uid,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'key': this.init.key,
      'location': 1,
    }}
    var res = await this.apiCall('/user/follow_map_shops', shoplist_req)

    this.$set(this, 'result', res)
    if (!res) return false;

    // 20201214 NEW_DEV-734 A12-No.08 cyber 鐘 start
    /* なんのためにソートし直してるか不明なため一旦コメントアウト
    if (res.shop_list) {
      res.shop_list = this.sortByKey(res.shop_list, "id");
    }
    */
    // 20201214 NEW_DEV-734 A12-No.08 cyber 鐘 end

    /* ルーティングAPI実行 ※GETの場合は、paramsを設定する */
    var routing_req = { params: {
      'sid': this.init.sid,
      'device_uid': this.init.device_uid,
      'key': this.init.key,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'feature_id': 'mypage',
      'service_cd': this.init.service_cd
    }};
    var routing_res = await this.apiCall('/user/routing', routing_req, 'get');
    if (!routing_res) {
      this.loading = false
      return false
    }
    this.$set(this, 'routing', routing_res);
    console.log(this.routing);
  },
  mounted(){

  },
  watch: {
    changeShopList: function() {
     // 20201214 NEW_DEV-734 A12-No.08 cyber 鐘 start
      this.changeIndex();
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
        this.$refs.slick.goTo(0, true)
      })
      this.changeCenter()
    },
  },
  computed: {
      changeShopList(){
        const data = this.result.shop_list;
        if(this.refecture_id){
          const result = data.filter(x => x.state == this.refecture_id)
          return result
        }else{
          return data
        }
      }
  },
  methods: {
    changeCenter() {
      this.center = this.changeShopList[0].location
    },
    changeLocation(location) {
      this.center = location
    },
    handleAfterChange(event, slick, currentSlide) {
      this.center = this.changeShopList[currentSlide].location
    },
    async followSet(flg, index, shop_id){
      try {
        this.loading = true
        /* 店舗フォローAPIを叩く */
        var req = {
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'shop_id': shop_id,
          'flg': flg,
        }
        var follow_res = await this.apiCall('/user/favorite', req, 'post');
        if (!follow_res) return false;

        this.changeShopList[index].follow_status = flg
        this.loading = false
      } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        this.loading = false
      }
    },
    changeRefecture(refecture_id){
      this.refecture_id = refecture_id
      //console.log(this.refecture_id)
    },
    MakerClick(location, index){
      this.center = location
      this.$refs.slick.goTo(index, true)
    },
    NextRouting(){
      var tonative_req = {
        'destination': this.routing.destination,
        'feature_id': this.routing.feature_id,
        'screen_id': this.routing.screen_id,
        'transition_type': this.routing.transition_type,
        'user_id': this.init.user_id,
        'user_name': this.init.nickname, // nativeにはニックネームを返す
        'device_uid': this.init.device_uid,
      };
      console.log(tonative_req)

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    changeIndex() {
      this.currentIndex = 0;
      if (this.changeShopList.length > 0) {
        let index_shopId = 0;
        let minShopid = this.changeShopList[0].id;
        for (var i = 1; i < this.changeShopList.length; i++) {
          if (minShopid > this.changeShopList[i].id) {
            minShopid = this.changeShopList[i].id;
            index_shopId = i;
          }
        }

        this.currentIndex = index_shopId;
      }
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x < y ? 1 : 0;
      });
    }
    // 20201214 NEW_DEV-734 A12-No.08 cyber 鐘 end
  }
}
</script>

<style scoped>
a {
    color: #333333;
}

#map {
    width: 100vw;
    height: 100vh;
}

#maphead {
    background-color: rgba(255, 178, 0, 0.83);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
}

.slider {
    width: 100vw;
    position: fixed;
    bottom: 90px;
    z-index: 200;
}

.slick-slider >>> .slick-list {
  overflow: visible;
}

.btns {
    width: 100vw;
    position: fixed;
    bottom: 15px;
    z-index: 200;
}

.followbtn {
    position: absolute;
    top:0;
    right: 0;
}

.star {
    margin-top: -60px;
}
.aligin_center {
  height: 62px;
  display: flex;
  align-items: center;
}

</style>
